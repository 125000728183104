<template>
  <div class="pay">
    <Header :title="'支付'" :back="true"/>
    <div class="pay-content">
      <div class="need-pay box-items">
        <div class="pay-title theme-color">亚洲分期-{{periods}}月期</div>
        <div class="pay-subtitle">支付金额</div>
        <div  class="pay-money theme-color">${{monies |formatFloat}}</div>
      </div>
      <div class="flex-box xj-box  box-items">
        <div>
          <img src="@/assets/icon/xj.png"/>
        </div>
        <div>
          <div>现金余额支付</div>
          <div class="gray-text">可用余额 {{canAmount|formatFloat}} 美元</div>
        </div>
        <div class="radio-box"><el-radio v-model="radio" label="1">&nbsp;</el-radio></div>
      </div>
      <div class="buy-btn">
        <button class="defined-btn" v-if="Number(canAmount)>Number(monies)" @click="handerConfirm" style="width:90%">确认预约买入</button>
        <button class="defined-btn" v-else @click="inmoney" style="width:90%" >余额不足，请充值</button>
      </div>
    </div>
    <el-dialog
      :visible.sync="drawer"
      width="85%"
      :show-close="false"
      custom-class="pwd-box"
      >
      <div class="draw-box">
        <div class="close-icon" @click="drawer=false"><i class="el-icon-circle-close theme-color"></i></div>
        <div class="draw-title">请输入交易密码</div>
        <div>
          <Password ref="passwordInputRef"
            mask
            :pwdd.sync="password"
            @complete="onPasswordInputComplete"/>
        </div>
        <div class="draw-pwd theme-color" @click="forgetPwd">忘记密码？</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Password from '@/components/Password/Index'

export default {
  components: {
    Header,
    Password
  },
  data(){
    return{
      periods:this.$route.query.periods,//期数
      monies:this.$route.query.mo,//金额
      auto:this.$route.query.automatic, // 是否自动续费
      canAmount:0,
      drawer:false,
      radio:'1',
      password:''

    }
  },
  created(){
    this.getAmount()
  },
  methods:{
    getAmount(){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'cash'}}).then(res => {
        this.canAmount = res.cash.use
      })
    },
    buyStages(pwd){
      let data = {
        term:this.periods,
        money:this.monies,
        name:'installment',
        payment:'cash',
        auto:this.auto,
        password:pwd
      }
      this.$store.dispatch('stages/buyStages',data).then((res) => {
         this.$router.push({path:'paysuccess',query:{key:res.inorder}})
      }).finally(()=>{
        this.password=null
      })
    },
    handerConfirm(){
      this.drawer = true;
    },
    onPasswordInputComplete(pwd){
      this.buyStages(pwd)
    },
    forgetPwd(){
      this.$router.push({name:'forgetpwd'})
    },
    // 充值
    inmoney(){
      let info = JSON.parse(localStorage.getItem('info'));
      if(info.validate!='wait'){
        // 进入账户阶段
        this.$router.push({path:'transferin'})
      }else{
        this.$router.push({path:'auth'})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pay{
  .pay-content{
    position: relative;
    height: calc(100vh - 3.6rem);
    .pay-title{
      background: #ffff;
      line-height: 3rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
     text-align: center;
    }
    .pay-subtitle{
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: center;
      font-size: .8rem;
    }
    .pay-money{
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      text-align: center;
      font-size: 2rem;
    }
    .need-pay{
      margin-top:2.5%;
      padding:5% 0%;
    }
    .xj-box{
      // line-height: 3rem;
      background: #ffff;
      margin-top:2.5%;
      >:first-child{
        img{
          width: 30px;
          margin-top:8px;
          margin-right:10px;
        }
        vertical-align: middle;
        width: 40px;
        text-align: center;
      }
      
    }
    .xj-box{
      // width: 100%;
      // margin:2.5% 0%;
      line-height: 1.5;
      padding: 2.5% 2.5%;
      position: relative;
      .gray-text{
        font-size: .8rem;
        color: #464646;;
      }
      .radio-box{
        position: absolute;
        right: 3%;
        top:20px
      }
    }
    .buy-btn{
      position: absolute;
      // bottom: 4rem;
      text-align: center;
      width: 100%;
      margin-top:30px;
    }
  }
}
.draw-box{
  padding:2% 3%;
  :not(:first-child)div{
    margin-bottom:10px;
    margin-left:5%;
    margin-right:5%;
    
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
}
.draw-title{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px
}
.utitle{
  color: #464646;
  // line-height: 2;
  font-size: .9rem;
}
 .draw-pwd{
    margin-top:1rem;
    text-align: right;
     margin-right:5%;
    font-size: .8rem;
  }
::v-deep{
  .el-drawer__header{
    text-align: center;
  }
}
</style>
